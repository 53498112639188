import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="divine"></a><h2>Divine</h2>
    <a id="feats"></a><h3>FEATS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
Deities can obtain the feats described here, in addition to any
standard feats.
    <h5><a id="blindsight-5-ft-radius"></a>BLINDSIGHT,5-FT.RADIUS
[GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Base
attack bonus +4, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#blind-fight">Blind-Fight</a>, Wisdom 19.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Using senses
such as acute hearing and sensitivity to vibrations, you detect the
location of opponents who are no more than 5 feet away from you. <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="abilitiesAndConditions.html#invisible">Invisibility</a> and
darkness are irrelevant, though it you discern
incorporeal beings.</p>
    <h5><a id="disguise-spell"></a>DISGUISE SPELL [METAMAGIC]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Bardic
music, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#perform">Perform</a> 12 ranks.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You have
mastered the art of casting spells unobtrusively, mingling verbal and
somatic components into its music and performances so that others
rarely catch you in the act of casting a spell. Like a silent, stilled
spell, a disguised spell can&rsquo;t be identified through <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a>.
Your performance is obvious to everyone in the vicinity, but the fact
that you are casting a spell isn&rsquo;t. Unless the spell visibly
emanates from you or observers have some other means of determining its
source, they don&rsquo;t know where the effect came from. A disguised
spell uses up a spell slot one level higher than the spell&rsquo;s
actual level.</p>
    <h5><a id="divine-might"></a>DIVINE MIGHT [DIVINE ]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Str 13, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="specialAttacks.html#turn">turn
or rebuke undead</a> ability, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#power-attack">Power
Attack</a>.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> As a free
action, spend one of your turn or rebuke undead attempts to add your
Charisma bonus to your weapon damage for 1 full round.</p>
    <h5><a id="divine-vengeance"></a>DIVINE VENGEANCE [DIVINE ]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Ability to
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="specialAttacks.html#turn">turn undead</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#extra-turning">Extra
Turning</a>.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can spend
one of your turn undead attempts to add 2d6 points of sacred energy
damage to all your successful melee attacks against undead until the
end of your next action. This is a supernatural ability.</p>
    <h5><a id="energy-substitution"></a>ENERGY SUBSTITUTION
[METAMAGIC]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Any other
metamagic feat, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#knowledge">Knowledge</a> (arcana) 5 ranks.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You choose
one type of energy: acid, cold, electricity, fire, or sonic. When
employing a spell with the acid, cold, electricity, fire, or sonic
designator, you can modify the spell to use your chosen type of energy
instead. The altered spell uses a spell slot of the spell&rsquo;s
normal level.
      <p>{`The altered spell works normally in all respects except the type of
damage dealt. `}</p></p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can gain
this feat multiple times. Each time the feat applies to a different
type of energy.</p>
    <h5><a id="extra-music"></a>EXTRA MUSIC [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisite:</span> Bardic
music.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can use
your bardic music four extra times per day.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> Bards without
the Extra Music feat can use bardic music once per day per level.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> You can gain
this feat multiple times, adding another four uses of bardic music each
time.</p>
    <h5><a id="eyes-in-the-back-of-your-head"></a>EYES IN THE BACK
OF YOUR HEAD [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Base
attack bonus +3, Wis 19.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Attackers do
not gain the usual +2 attack bonus when flanking you. This feat grants
no effect whenever you are attacked without benefit of your Dexterity
modifier to AC, such as when you are flat-footed or when you are the
target of a rogue&rsquo;s sneak attack.</p>
    <h5><a id="fleet-of-foot"></a>FLEET OF FOOT [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Dex 15,
Run.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When running
or <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#charge">charging</a>, you can make a single
direction change of 90 degrees or
less. You can&rsquo;t use this feat while wearing medium or heavy
armor, or when carrying a medium or heavy load.&nbsp; If you are
charging, you must move in a straight line for 10 feet after the turn
to maintain the charge.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> Without this
feat, you can run or charge only in a straight line.</p>
    <h5><a id="hold-the-line"></a>HOLD THE LINE [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#combat-reflexes">Combat
Reflexes</a>, base attack bonus +2.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You may make
an attack of opportunity against a <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#charge">charging</a> opponent who enters an
area
you threaten.&nbsp; Your attack of opportunity happens immediately
before the charge attack is resolved.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> You only get
an attack of opportunity against a character that exits a square you
threaten.</p>
    <h5><a id="jack-of-all-trades"></a>JACK OF ALL TRADES [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisite:</span> You must be
at least 6th level.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can use
any skill untrained, even those that normally require training.</p>
    <h5><a id="knock-down"></a>KNOCK-DOWN [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Base
attack bonus +2, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#improved-trip">Improved Trip</a>, Str 15.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Whenever you
deal 10 or more points of damage to your opponent in melee, you make a
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#trip">trip</a> attack as a free action
against the same target.</p>
    <h5><a id="persistent-spell"></a>PERSISTENT SPELL [METAMAGIC]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisite:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#extend-spell">Extend
Spell</a>.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> A persistent
spell has a duration of 24 hours. The persistent spell must have a
personal range or a fixed range. Spells of instantaneous duration
cannot be affected by this feat, nor can spells whose effects are
discharged.&nbsp; You need not concentrate on spells such as <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-magic">detect
magic</a> or <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="spellsDtoE.html#detect-thoughts">detect thoughts</a> to be aware
of the mere presence of absence of
the things detected, but you must still concentrate to gain additional
information as normal.&nbsp; Concentration on such a spell is a
standard action that does not provoke an attack of opportunity.&nbsp; A
persistent spell uses up a spell slot six levels higher than the
spell&rsquo;s actual level.</p>
    <h5><a id="plant-control"></a>PLANT CONTROL [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="#plant-defiance">Plant
Defiance</a>, ability to cast <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="spellsS.html#speak-with-plants">speak with plants</a>.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#turn">rebuke or command </a>plant creatures
as an evil cleric rebukes undead. To
command a plant, you must be able to speak with it via a speak with
plants effect, though it may do so mentally if desired. This ability is
usable a total number of times per day equal to 3 + your Charisma
modifier. You use your highest caster level to determine the level at
which you rebuke plants.</p>
    <h5><a id="plant-defiance"></a>PLANT DEFIANCE [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisite:</span> Ability to
cast <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="spellsDtoE.html#detect-animals-or-plants">detect animals or
plants</a>.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> You can <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#turn">turn</a>
(but not destroy) plant creatures as a good cleric turns undead. When
determining the result of a turning attempt, treat all destruction
results as normal turning. Treat immobile plant creatures as creatures
unable to flee. This ability is usable a total number of times per day
equal to 3 + your Charisma modifier. You use your highest caster level
to determine the level at which you turns plants.</p>
    <h5><a id="power-critical"></a>POWER CRITICAL [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#weapon-focus">Weapon
focus</a> (chosen weapon), base attack bonus +4
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When using
the weapon you selected, you gain a +4 bonus on the roll to confirm a
threat.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> A fighter may
select Power Critical as one of his fighter bonus feats.
      <p>{`You can gain Power Critical multiple times. Each time you take the
feat, it may be with a different weapon or the same weapon.`}{` `}{` If
you take it with the same weapon, the effects of the feats stack.`}</p></p>
    <h5><a id="reach-spell"></a>REACH SPELL [METAMAGIC]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Benefit:</span> You may cast a
spell that normally has a range of touch at any distance up to 30 feet.
The spell effectively becomes a ray, so you must succeed at a ranged
touch attack to bestow the spell upon the recipient. A reach spell uses
up a spell slot two levels higher than the spell&rsquo;s actual level.
    <h5><a id="repeat-spell"></a>REPEAT SPELL [METAMAGIC]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Any other
metamagic feat.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> A repeated
spell is automatically cast again at the beginning of your next round
of actions. No matter where you are, the secondary spell originates
from the same location and affects the same area as the primary spell.
If the repeated spell designates a target, the secondary spell
retargets the same target if the target is within 30 feet of its
original position; otherwise the secondary spell fails to go off. A
repeated spell uses up a spell slot three levels higher than the
spell&rsquo;s actual level. Repeat Spell cannot be used on spells with
a range of touch.</p>
    <h5><a id="sacred-spell"></a>SACRED SPELL [METAMAGIC]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Benefit:</span> Half of the
damage dealt by a sacred spell results directly from divine power and
is therefore not subject to being reduced by protection from elements
or similar magic. The other half of the damage dealt by the spell is as
normal. A sacred spell uses up a spell slot two levels higher than the
spell&rsquo;s actual level. Only divine spells can be cast as sacred
spells.
    <h5><a id="sharp-shooting"></a>SHARP-SHOOTING [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#point-blank-shot">Point
Blank Shot</a>, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#precise-shot">Precise Shot</a>, base attack bonus
+3.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> Your targets
only receive a +2 bonus to Armor class due to cover.&nbsp; This feat
has no effect against foes with no cover or total cover.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Normal:</span> Cover normally
gives a +4 bonus to AC.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> A fighter may
select Sharp-Shooting as one of his bonus feats.</p>
    <h5><a id="subdual-substitution"></a>SUBDUAL SUBSTITUTION
[METAMAGIC]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Any other
metamagic feat, <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="skillsAll.html#knowledge">Knowledge</a> (arcana) 5 ranks.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When
employing a spell with the acid, cold, electricity, fire, or sonic
designator, you can modify the spell to deal subdual damage instead of
the indicated type of energy damage. The altered spell uses a spell
slot of the spell&rsquo;s normal level.
      <p>{`The altered spell works normally in all respects except the type of
damage dealt. `}</p></p>
    <a id="superior-expertise"></a><h5>SUPERIOR EXPERTISE [GENERAL]</h5>
    <span style={{
      "fontWeight": "bold"
    }}>Prerequisites:</span> Int 13,
    <a style={{
      "color": "rgb(87, 158, 182)"
    }} href="featsAll.html#combat-expertise">Combat Expertise</a>, base
attack bonus +6.
    <p><span style={{
        "fontWeight": "bold"
      }}>Benefit:</span> When you use
the Combat Expertise feat to improve your Armor Class, the number you
subtract from your attack and add to your AC can be any number that
does not exceed your base attack bonus.
      <p>{`This feat eliminates the +5 maximum for the Combat Expertise feat.`}</p></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      